<template>
  <section class="grey lighten-4">
    <v-container class="pa-2 pa-sm-12">
      <app-title>Présentation de l'URPS Pharmaciens Grand Est</app-title>
      <p class="text-justify">
        L'URPS Pharmaciens Grand Est est une organisation représentative de la
        profession dont l’objectif principal est de placer le pharmacien
        d'officine comme acteur clé du système de santé en l’adaptant à son
        nouvel exercice issu des missions confiées dans la Loi HPST (prévention,
        éducation thérapeutique...) et de celles confiées par la convention
        nationale régissant les rapports entre l’assurance maladie et les
        pharmaciens d’officine.
      </p>
      <p>
        L’URPS s'engage également à renforcer la coordination avec les autres
        professionnels de santé et tout autre acteur du système de santé. De
        manière plus générale, les activités de l'URPS sont définies précisément
        dans l’Article R.4031-2 du Code de la Santé Publique et en font un
        interlocuteur privilégié pour les ARS. L’URPS Pharmaciens Grand Est
        insiste, dans ses actions, très fortement sur les outils nécessaires à
        la pratique officinale, que cela soit dans un objectif de formation ou
        d’expérimentation.
      </p>
    </v-container>
  </section>
</template>

<script>
export default {
  name: 'UrpsSection',

  components: {
    'app-title': () => import('../../../core/Title')
  }
}
</script>
